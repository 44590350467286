import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import { Image } from 'react-bootstrap'
import { CAROUSEL_IMAGES } from '../config'

class ImageCarousel extends React.Component {

    renderCarouselItems() {
        return CAROUSEL_IMAGES.map(imageSrc => {
            return (
                <Carousel.Item>
                    <Image
                        fluid
                        src={imageSrc}
                    />
                </Carousel.Item>
            )
        })
    }

    render() {
        return (
            <Carousel
                interval={7000}
                style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                {this.renderCarouselItems()}
            </Carousel>
        )
    }

}

export default ImageCarousel

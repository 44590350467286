import React from "react"

class Map extends React.Component {
    render() {
        return (
            <div >
                <iframe 
                src="https://www.google.com/maps/d/embed?mid=1e2310BNWs72l23TeSCCme9OkOrQ&hl=el&ehbc=2E312F" 
                width="100%" 
                height="700px"/>
            </div>
        );
    }
}

export default Map
import React from 'react'
import { Navbar } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import { Image } from 'react-bootstrap'
import { navbar_color } from '../colors'
import { FB_LINK, IG_LINK, LOGO_SRC } from '../config'

class Header extends React.Component {

    render() {
        return (
            <div>
                <Navbar fixed='top' style={{ background: navbar_color, boxShadow: '10px 3px 7px 4px  #999999' }}>
                    <Container >
                        <Navbar.Brand href="#"><Image src={LOGO_SRC} style={{
                            boxShadow: '0px 2px 7px 4px  #999999'
                        }}
                            width={140} /></Navbar.Brand>
                        <Navbar.Toggle />
                        <Navbar.Collapse className="justify-content-end">
                            <Navbar.Text>
                                <Image
                                    onClick={() => window.open(FB_LINK, "_blank")}
                                    src='fb-icon.png'
                                    width={30}
                                    height={30}
                                    style={{ marginRight: 10, cursor: 'pointer' }}
                                />

                                <Image
                                    onClick={() => window.open(IG_LINK, "_blank")}
                                    src='ig-icon.png'
                                    width={30}
                                    height={30}
                                    style={{ marginRight: 15, cursor: 'pointer' }}
                                />
                                Τηλ: 210-8169458
                            </Navbar.Text>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        )
    }
}

export default Header

import React from 'react'
import Header from './Header'
import ImageCarousel from './ImageCarousel'
import Map from './Map'
import { BACKGROUND_IMAGE } from '../config'

class Home extends React.Component {

    render() {
        return (
            <div style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/' + BACKGROUND_IMAGE})`,

            }}>
                <Header />
                <div style={{
                    marginLeft: '20%', marginRight: '20%', paddingTop: '5%',
                }}>

                    <div style={{
                        boxShadow: '0px 2px 7px 4px  #999999'
                    }}>
                        <ImageCarousel />
                    </div>

                    <div style={{
                        marginTop: '4%',
                        width: '100%',
                        height: '100%',
                        boxShadow: '0px 2px 7px 4px  #999999'
                    }}>

                        <Map />
                    </div>
                </div>

            </div>
        )
    }

}

export default Home
